window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');

require('bootstrap');
require('../common/object.js');

$(function() {

    // Add CSRF token to jQuery Ajax.
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': document.head.querySelector('meta[name="csrf-token"]')
        }
    });

    // Slider
    $('.page .slider').owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        mouseDrag: false,
        touchDrag: false
    });

    // Create link for images with ckeditor
    $('figure figcaption').each(function (key, item) {
        // Get link
        var link = $(item).find('a').attr('href');

        // Add link to figure
        $(item).parents('figure').find('img').wrap('<a target="_blank" href="' + link + '"></a>');

        // Remove figcaption
        $(item).remove();
    });

    // Ajax post for normal cookies
    $('.habitats-website-partials-Cookies')
        .find('form')
        .on('submit', (event) =>
        {
            // Ajax call
            $.post('/save-cookies', { allCookies: 1, '_token': $('meta[name="csrf-token"]').attr('content') } )
                .done(() => {
                    $('.habitats-website-partials-Cookies').find('form').hide();
                });

            // Stop submit
            return false;
        });

    // Cookie module
    $('.habitats-website-partials-cookies-Modal')
        .find('form button')
        .on('click', (event) =>
        {
            // Get form data
            let data = $('.habitats-website-partials-Cookies').find('form').serializeArray();
            data.push({ name: event.currentTarget.name, value: event.currentTarget.value });
            data.push({ '_token': $('meta[name="csrf-token"]').attr('content') });

            // Ajax call
            $.post('/save-cookies', data)
                .done(() => {
                    // Hide modal
                    $('.habitats-website-partials-cookies-Modal').parent().modal('hide');

                    // Hide 'footer' cookies
                    $('.habitats-website-partials-Cookies').find('form').hide();
                });

            // Stop submit
            return false;
        });

    // MMenu
    $menu = $('.nav-mmenu').mmenu({
        // options
        "extensions": [
            "border-full",
            "fx-menu-slide",
            "fx-panels-slide-100",
            "listview-huge",
            "pagedim-black"
        ],
    }, {
        "offCanvas": {
            "position": "right",
            "pageSelector": "#my-page"
        },
        classNames: {
            selected: "active",
            fixedElements: {
                fixed: "Fixed"
            }
        }

    });

    var $icon = $(".show-menu .menu");
    var API = $menu.data("mmenu");

    $icon.on("click", function () {
        API.open();
    });

    // $('.select2').select2({});

});
